footer {
    position: fixed;
    bottom: 0%;
    width: 100%;
}

.App {
    text-align: center;
}

.iconText {
    margin: 0 0.5rem;
}

.navbarStyle {
    padding: 0.5rem 1rem !important;
    background-color: lightsteelblue;
    height: 50px;

}

.navbarButton {
    color: #fff !important;
    padding: 0.5rem 1rem !important;
}

.iconText {
    margin: 0 0.5rem;
}

.navbarStyle {
    padding: 0.5rem 1rem;
}

.navbarButton {
    color: #fff !important;
}

.data-area-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.col-form-label {
    font-weight: bold;
    text-align: left;
}

.form-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: gray;
    font-style: italic;
    opacity: 1; /* Firefox */
    font-size: 0.8em;
  }
  
  .form-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: gray;
    font-style: italic;
    font-size: 0.8em
  }
  
  .form-input::-ms-input-placeholder { /* Microsoft Edge */
    color: gray;
    font-style: italic;
    font-size: 0.8em
  }

  .form-check-input {
    /* Adjust the position of the checkbox */
    border-radius: 0%  !important;
    border-color: #42a5f5 !important;
    vertical-align: -webkit-baseline-middle !important;

  }
  
  .submitted:invalid {
    border-color: red;
    border-radius: 0%;
    border-width: 2px;
  }


.tableColumn {
    word-break: break-all;
}

.table-responsive {
    max-height: 37rem !important;
}

.warningMessage {
    color: red;
}

.card-title {
    text-align: center;
}

.signInButton {
    margin: 1rem;
}

#webchat {
    height: calc(100% - 50px);
    overflow: hidden;
    position: fixed;
    top: 50px;
    width: 100%;
}


.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.row {
    display: table-row;
}

.cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
}

.cell:first-child {
    width: 45%;
}